/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, ColumnWrapper, Image, Text, Title, SeparateLine, SeparateLineWrap, Subtitle, VarColumn, Clearfix, Form, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"wqsbjb8uq0r"}>
        </ColumnCover>


        <Column className="--menu --style1 pb--12" style={{"paddingTop":21}} menu={true} name={"tlacitkanabidka"}>
          
          <Menu className="--style1">
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style2 --center">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--style5 fs--16" style={{"style":5}} href={"#apartmany"} target={""} content={"O apartmánech"}>
              </Button>

              <Button className="btn-box btn-box--style5 fs--16" style={{"style":5}} href={"#nabidkaapartmanu"} target={""} content={"Nabídka apartmánů"}>
              </Button>

              <Button className="btn-box btn-box--style5 fs--16" style={{"style":5}} href={"#mapa"} target={""} content={"Kudy k nám"}>
              </Button>

              <Button className="btn-box btn-box--style5 fs--16" style={{"style":5}} href={"#aktivity-v-okoli"} target={""} content={"Okolí"}>
              </Button>

              <Button className="btn-box btn-box--style5 fs--16" style={{"style":5}} href={"#kontakt"} target={""} content={"Kontakt"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column className="css-1f1u9oy --style1 --full pb--30 pt--30" style={{"backgroundColor":"rgba(134, 124, 180, 1)"}} name={"uvod"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"backgroundColor":"rgba(255, 255, 255, 0.29)"}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":500,"paddingBottom":0}}>
              
              <Image className="--invert" style={{"maxWidth":550,"marginBottom":38.399993896484375}} src={"https://cdn.swbpg.com/t/2552/f83edd5ad0ad4ac48417aec338ca1b77_s=860x_.png"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2552/f83edd5ad0ad4ac48417aec338ca1b77_s=350x_.png 350w, https://cdn.swbpg.com/t/2552/f83edd5ad0ad4ac48417aec338ca1b77_s=660x_.png 660w, https://cdn.swbpg.com/t/2552/f83edd5ad0ad4ac48417aec338ca1b77_s=860x_.png 860w, https://cdn.swbpg.com/t/2552/f83edd5ad0ad4ac48417aec338ca1b77_s=1400x_.png 1400w, https://cdn.swbpg.com/t/2552/f83edd5ad0ad4ac48417aec338ca1b77_s=2000x_.png 2000w"}>
              </Image>

              <Text className="text-box pt--50" content={"Zadejte text"}>
              </Text>

              <Text className="text-box pt--60" content={"Zadejte text"}>
              </Text>

              <Button className="btn-box btn-box--style3 btn-box--center fs--18 mb--08 mt--0 pt--0" style={{"maxWidth":1090,"marginTop":null,"paddingTop":null,"marginBottom":null,"paddingBottom":11.8046875}} href={"#nabidkaapartmanu"} content={"APARTMÁN<br>&nbsp;od 1.500,-za noc <br>pro dva"}>
              </Button>

              <Button className="btn-box btn-box--style3 fs--20 mb--08 pb--0 pt--02" style={{"marginBottom":null}} href={"#formular"} content={"&nbsp; REZERVACE&nbsp;&nbsp;"}>
              </Button>

              <Button className="btn-box btn-box--style3 fs--20" style={{"maxWidth":403}} href={"https://www.e-chalupy.cz/jizni_morava/ubytovani-bulhary-apartmany-u-sadu-7219.php"} content={"&nbsp;OBSAZENOST&nbsp;"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--0 pb--0 pt--08" style={{"marginTop":null,"paddingTop":null,"paddingBottom":null}} name={"apartmany"} layout={"l13"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box title-box--style1 ff--1" content={"O APARTMÁNECH"}>
              </Title>

              <Text className="text-box" style={{"paddingTop":48,"marginBottom":0,"paddingBottom":34.7734375}} content={"Nabízíme ubytování v nově zrekonstruovaném domě \nv malebné vesničce Bulhary, která se nachází mezi Mikulovem a Lednicí v blízkosti Pálavy přímo na řece Dyji.  \n\nTato obec je proslavena pěstováním vinné révy a výrobou vína, což hostům umožňuje ochutnávku místních vín\nv soukromých sklípcích.&nbsp;"}>
              </Text>

              <Button className="btn-box btn-box--style3" href={"#nabidkaapartmanu"} content={"NABÍDKA UBYTOVÁNÍ"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image style={{"marginBottom":0}} src={"https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=860x_.JPG"} svg={false} href={"#galeriespolecnepr"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=350x_.JPG 350w, https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=660x_.JPG 660w, https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=860x_.JPG 860w, https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=2000x_.JPG 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--0 pb--0 pt--0" style={{"paddingTop":null,"paddingBottom":null}} name={"nabidkaapartmanu"} layout={"l10"}>
          
          <ColumnWrap className="column__flex --center el--4 mb--30 mt--0 pb--0 flex--stretch" columns={"4"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center ff--1 fs--26 w--900" style={{"paddingBottom":0}} content={"<span style=\"color: rgb(18, 18, 18);\"><a href=\"#galeriest1\">STUDIO 1</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--1 fs--13 w--500" style={{"paddingBottom":18.5625}} content={"<span style=\"color: rgb(0, 0, 0);\">30 m2 - Max. 4 osoby <br>Dvoulůžko, 1 samostatné lůžko \ns možností přistýlky. <br><span style=\"font-weight: bold;\">Studio lze propojit s Apartmánem 2</span><br></span><span style=\"color: rgb(255, 255, 255);\">&nbsp;</span><br><span style=\"color: rgb(0, 0, 0);\">Kuchyňská linka </span><span style=\"color: rgb(184, 233, 134);\">•</span><span style=\"color: rgb(0, 0, 0);\"> Parkování </span><span style=\"color: rgb(184, 233, 134);\">•</span><span style=\"color: rgb(0, 0, 0);\"> Dvůr se zahradou </span><span style=\"color: rgb(184, 233, 134);\">•</span><span style=\"color: rgb(0, 0, 0);\"> Koupelna </span><span style=\"color: rgb(184, 233, 134);\">•</span><span style=\"color: rgb(0, 0, 0);\"> TV&nbsp;<br></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--1 fs--20 w--900" style={{"paddingTop":40,"paddingBottom":22.85625457763672}} content={"<span style=\"color: rgb(126, 211, 33);\">1-3 osoby 1 500 Kč/noc<br>3-4 osoby 2 200 Kč/noc</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center ff--1 fs--26 w--900 lh--16 pb--0" style={{"paddingTop":0,"paddingBottom":null}} content={"<span style=\"color: rgb(18, 18, 18);\"><a href=\"#galerieap2\">APARTMÁN 2</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--1 fs--13 w--500" style={{"maxWidth":246,"paddingTop":0,"paddingBottom":1.578125}} content={"<span style=\"color: rgb(18, 18, 18);\">45 m2 - max. 6 osob\n<br>Ložnice - manželská postel, patrová postel (2 plnohodnotná lůžka),&nbsp; <br>2 jednolůžka s možností přistýlky(šuplík)<br><span style=\"font-weight: bold;\">Apartmán lze propojit se Studiem 1&nbsp;</span><br><br>&nbsp;Kuchyň -Kuchyňská linka (myčka, elektrický sporák s troubou, mikrovlnná trouba) </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> Parkování </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> Dvůr se zahradou </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> Koupelna </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> TV&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--1 fs--20 w--900 pb--0 pt--10" style={{"maxWidth":239,"marginTop":10}} content={"<span style=\"color: rgb(126, 211, 33);\">&nbsp;1-2 osoby&nbsp; 1 500 Kč/noc<br>&nbsp;3-4 osoby&nbsp; 2 200 Kč/noc<br>&nbsp; &nbsp; &nbsp;5 osob&nbsp; &nbsp; 2 650 Kč/noc<br>&nbsp; &nbsp; &nbsp;6 osob&nbsp; &nbsp; 3 100 Kč/noc</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center ff--1 fs--26 w--900" style={{"maxWidth":269,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\"><a href=\"#galerieap3\">APARTMÁN 3</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--1 fs--13 w--500" style={{"paddingBottom":0.515625}} content={"<span style=\"color: rgb(18, 18, 18);\">48 m2 - max. 5 osob <br>1. ložnice - manželská postel + 1&nbsp; &nbsp; &nbsp; &nbsp;<br>&nbsp; &nbsp; samostatné lůžko <br>2. ložnice - 2 samostatná lůžka&nbsp;<br><br>Kuchyňská linka (myčka, indukční vařič, mikrovlnná trouba) </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> Parkování </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> Dvůr se zahradou</span><span style=\"color: rgb(126, 211, 33);\"> •</span><span style=\"color: rgb(18, 18, 18);\"> Koupelna </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> TV&nbsp;&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--1 fs--20 w--900" style={{"paddingBottom":21.524993896484375,"marginTop":8,"paddingTop":1}} content={"<span style=\"color: rgb(126, 211, 33);\">&nbsp; &nbsp; <br>&nbsp;<br>1-2 osoby  1 500 Kč/noc\n 3-4 osoby  2 200 Kč/noc&nbsp; &nbsp; &nbsp; 5 osob&nbsp; &nbsp;2 650 Kč/noc</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--24 w--900" content={"<span style=\"color: rgb(18, 18, 18);\"><a href=\"#galeriest4\">STUDIO 4</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left fs--13 w--500 mb--0 mt--10 pb--0" content={"<span style=\"color: rgb(18, 18, 18);\">18 m2 - max. 2 osoby\nDvoulůžko.\nVchod z průjezdu. <br><br>&nbsp;Kuchyňská linka (indukční vařič, rychlovarná konvice) </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> Dvůr se zahradou </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> Parkování  </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> Koupelna </span><span style=\"color: rgb(126, 211, 33);\">•</span><span style=\"color: rgb(18, 18, 18);\"> TV<br></span><br>"}>
              </Text>

              <Text className="text-box text-box--left fs--20 w--900" style={{"paddingTop":17,"marginTop":11,"paddingBottom":4}} content={"<span style=\"color: rgb(126, 211, 33);\"><br>&nbsp; &nbsp; &nbsp;<br>&nbsp; 1-2 osoby 1 500Kč/noc</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--0" style={{"marginTop":0,"paddingTop":0}} name={"doplnujici-cenik"} layout={"l22"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="mb--20 mt--0 pb--08 pt--08" style={{"backgroundColor":"#f4f4f4"}}>
              
              <Text className="text-box text-box--right fs--36 w--900" style={{"maxWidth":1052}} content={"<span style=\"color: rgb(126, 211, 33);\">Doplňkový ceník</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08" style={{"backgroundColor":"#f4f4f4"}}>
              
              <Text className="text-box text-box--center fs--16 w--900" content={"Pes (malá rasa)"}>
              </Text>

              <Text className="text-box text-box--left w--900" content={"120 Kč / den"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08" style={{"backgroundColor":"#f4f4f4"}}>
              
              <Text className="text-box text-box--center w--900" content={"Postýlka"}>
              </Text>

              <Text className="text-box text-box--left w--900" content={"120 Kč / den"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"oddelovac3"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--25 pt--30" layout={"l23"} lightbox={true}>
          
          <ColumnWrap className="column__flex el--4" style={{"maxWidth":1173}} columns={"4"}>
            
            <ColumnWrapper style={{"maxWidth":241}}>
              
              <Image style={{"maxWidth":100}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-22.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Text className="text-box fs--16 w--300 text-box--invert ls--004 lh--16" style={{"maxWidth":173}} content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">WIFI</span> <span style=\"color: var(--black);\">na všech pokojích ZDARMA</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":76}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-33.svg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} content={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Text className="text-box fs--14 w--500" style={{"maxWidth":191}} content={"<span style=\"color: var(--black);\">Ubytování od 2 nocí.&nbsp; &nbsp; Studio 1 a Apartmán 2&nbsp; lze propojit pro větší skupinu ubytovaných.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":319}}>
              
              <Image style={{"maxWidth":76}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-16.svg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} content={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Text className="text-box fs--14 w--500 pr--0" style={{"maxWidth":175,"paddingRight":null}} content={"<span style=\"color: var(--black);\">Za domem velký dvůr se zahradou, kryté posezení, gril, ohniště, pískoviště, dětské hřiště, branky na minifotbal nebo houpačky.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":258}}>
              
              <Image style={{"maxWidth":74}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Auto-Moto/auto-moto-15.svg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} content={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Text className="text-box fs--14 w--500" style={{"maxWidth":166}} content={"<span style=\"color: var(--black);\"> Před domem zajištěno parkování na vlastním parkovišti.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"oddelovac1"} layout={"l6"}>
          
          <SeparateLineWrap style={{"backgroundColor":"rgba(126, 211, 33, 1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":null,"paddingBottom":null}} name={"galerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --full --center el--3" columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"galeriespolecnepr"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--26 w--300 mt--06" content={"<span style=\"font-weight: bold; color: rgb(18, 18, 18);\">Společné prostory</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=660x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/b45f38ed6c6f48e398de4abe0de14b93_s=860x_.jpg 860w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=860x_.JPG"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=350x_.JPG 350w, https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=660x_.JPG 660w, https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=860x_.JPG 860w, https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/2552/ee7d2d6a12b84ecfb20afef91cac924e_s=2000x_.JPG 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/61db777cd3b54b958454b3345c20d7c4_s=660x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/61db777cd3b54b958454b3345c20d7c4_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2552/61db777cd3b54b958454b3345c20d7c4_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2552/61db777cd3b54b958454b3345c20d7c4_s=860x_.jpeg 860w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/2552/c650d9ec26294fdfa647b25c9ee2e31f_s=860x_.JPG"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/c650d9ec26294fdfa647b25c9ee2e31f_s=350x_.JPG 350w, https://cdn.swbpg.com/t/2552/c650d9ec26294fdfa647b25c9ee2e31f_s=660x_.JPG 660w, https://cdn.swbpg.com/t/2552/c650d9ec26294fdfa647b25c9ee2e31f_s=860x_.JPG 860w, https://cdn.swbpg.com/t/2552/c650d9ec26294fdfa647b25c9ee2e31f_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/2552/c650d9ec26294fdfa647b25c9ee2e31f_s=2000x_.JPG 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/054700794445486a94ac7f63ae4c32e1_s=860x_.JPG"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/054700794445486a94ac7f63ae4c32e1_s=350x_.JPG 350w, https://cdn.swbpg.com/t/2552/054700794445486a94ac7f63ae4c32e1_s=660x_.JPG 660w, https://cdn.swbpg.com/t/2552/054700794445486a94ac7f63ae4c32e1_s=860x_.JPG 860w, https://cdn.swbpg.com/t/2552/054700794445486a94ac7f63ae4c32e1_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/2552/054700794445486a94ac7f63ae4c32e1_s=2000x_.JPG 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/2552/ac84f42ee4cc471eb63deaa5ba63c568_s=860x_.JPG"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/ac84f42ee4cc471eb63deaa5ba63c568_s=350x_.JPG 350w, https://cdn.swbpg.com/t/2552/ac84f42ee4cc471eb63deaa5ba63c568_s=660x_.JPG 660w, https://cdn.swbpg.com/t/2552/ac84f42ee4cc471eb63deaa5ba63c568_s=860x_.JPG 860w, https://cdn.swbpg.com/t/2552/ac84f42ee4cc471eb63deaa5ba63c568_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/2552/ac84f42ee4cc471eb63deaa5ba63c568_s=2000x_.JPG 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"galeriest1"} layout={"l9"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--36 w--300 mt--06" content={"<span style=\"color: rgb(18, 18, 18); font-weight: bold;\">Studio 1</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="mb--08 mt--0 pr--0" style={{"maxWidth":291}} src={"https://cdn.swbpg.com/t/2552/0e7e389f1f704fa6a8b0b33724a5c016_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/0e7e389f1f704fa6a8b0b33724a5c016_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/0e7e389f1f704fa6a8b0b33724a5c016_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/0e7e389f1f704fa6a8b0b33724a5c016_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/0e7e389f1f704fa6a8b0b33724a5c016_s=1400x_.jpg 1400w"} lightbox={true} position={null} RootClassName={"column__pic"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/2552/65b807f622944f5a9539e1f5e7201a5e_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/65b807f622944f5a9539e1f5e7201a5e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/65b807f622944f5a9539e1f5e7201a5e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/65b807f622944f5a9539e1f5e7201a5e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/65b807f622944f5a9539e1f5e7201a5e_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/aa468153f24645d2a43973a03d60ccfb_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/aa468153f24645d2a43973a03d60ccfb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/aa468153f24645d2a43973a03d60ccfb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/aa468153f24645d2a43973a03d60ccfb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/aa468153f24645d2a43973a03d60ccfb_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/d1fd1fd6990547188a8efdd51fdf02e6_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/d1fd1fd6990547188a8efdd51fdf02e6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/d1fd1fd6990547188a8efdd51fdf02e6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/d1fd1fd6990547188a8efdd51fdf02e6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/d1fd1fd6990547188a8efdd51fdf02e6_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"galerieap2"} layout={"l9"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--36 w--300 mt--06" content={"<span style=\"color: rgb(18, 18, 18); font-weight: bold;\">Apartmán 2</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/c7053c4d81f74e0aa6039247da8970a8_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/c7053c4d81f74e0aa6039247da8970a8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/c7053c4d81f74e0aa6039247da8970a8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/c7053c4d81f74e0aa6039247da8970a8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/c7053c4d81f74e0aa6039247da8970a8_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/2552/4d70d9f288dd45d4b041d74e67cd5d31_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/4d70d9f288dd45d4b041d74e67cd5d31_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/4d70d9f288dd45d4b041d74e67cd5d31_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/4d70d9f288dd45d4b041d74e67cd5d31_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/4d70d9f288dd45d4b041d74e67cd5d31_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/e550aa193eb841fd9bd5db02297039be_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/e550aa193eb841fd9bd5db02297039be_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/e550aa193eb841fd9bd5db02297039be_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/e550aa193eb841fd9bd5db02297039be_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/e550aa193eb841fd9bd5db02297039be_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/30a92d52c0a94ecfb922dbf0ae16f3cd_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/30a92d52c0a94ecfb922dbf0ae16f3cd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/30a92d52c0a94ecfb922dbf0ae16f3cd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/30a92d52c0a94ecfb922dbf0ae16f3cd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/30a92d52c0a94ecfb922dbf0ae16f3cd_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"galerieap3"} layout={"l9"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--36 w--300 mt--06" content={"<span style=\"color: rgb(18, 18, 18); font-weight: bold;\">Apartmán 3</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/42849f8a2add4541b984abaeecf553fc_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/42849f8a2add4541b984abaeecf553fc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/42849f8a2add4541b984abaeecf553fc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/42849f8a2add4541b984abaeecf553fc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/42849f8a2add4541b984abaeecf553fc_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/2552/77add09aabf242e38612be25f12acfd1_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/77add09aabf242e38612be25f12acfd1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/77add09aabf242e38612be25f12acfd1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/77add09aabf242e38612be25f12acfd1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/77add09aabf242e38612be25f12acfd1_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/10668be2d5c64745b6c1de8272ee45c8_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/10668be2d5c64745b6c1de8272ee45c8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/10668be2d5c64745b6c1de8272ee45c8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/10668be2d5c64745b6c1de8272ee45c8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/10668be2d5c64745b6c1de8272ee45c8_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/2552/5c5323a2fe66455d8287622beffb44ca_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/5c5323a2fe66455d8287622beffb44ca_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/5c5323a2fe66455d8287622beffb44ca_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/5c5323a2fe66455d8287622beffb44ca_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/5c5323a2fe66455d8287622beffb44ca_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/77e6909ce19a482bb666615476fc7593_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/77e6909ce19a482bb666615476fc7593_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/77e6909ce19a482bb666615476fc7593_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/77e6909ce19a482bb666615476fc7593_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/77e6909ce19a482bb666615476fc7593_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/2552/72f0cc37af62488198168ec27a12fa9c_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/72f0cc37af62488198168ec27a12fa9c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/72f0cc37af62488198168ec27a12fa9c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/72f0cc37af62488198168ec27a12fa9c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/72f0cc37af62488198168ec27a12fa9c_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"galeriest4"} layout={"l9"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--36 w--300 mt--06" content={"<span style=\"font-weight: bold; color: rgb(18, 18, 18);\">Studio 4</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/4b4b6754eaec4821a1d14d23386e3d9d_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/4b4b6754eaec4821a1d14d23386e3d9d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/4b4b6754eaec4821a1d14d23386e3d9d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/4b4b6754eaec4821a1d14d23386e3d9d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/4b4b6754eaec4821a1d14d23386e3d9d_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/154534de88b0449ea0c0b05f68145548_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/154534de88b0449ea0c0b05f68145548_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/154534de88b0449ea0c0b05f68145548_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/154534de88b0449ea0c0b05f68145548_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/154534de88b0449ea0c0b05f68145548_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2552/560c934d22494c348f031253cd1f869e_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2552/560c934d22494c348f031253cd1f869e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2552/560c934d22494c348f031253cd1f869e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2552/560c934d22494c348f031253cd1f869e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2552/560c934d22494c348f031253cd1f869e_s=1400x_.jpg 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"oddelovac2"} layout={"l6"}>
          
          <SeparateLineWrap style={{"backgroundColor":"rgba(184, 233, 134, 1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" name={"aktivity-v-okoli"} layout={"l13"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Image style={{"maxWidth":556,"paddingBottom":0}} src={"https://cdn.swbpg.com/t/2552/e7bc356b4a014e42915b6050b8249028_s=660x_.JPG"} svg={false} href={"https://mapy.cz/fotografie?x=16.7574120&y=48.8402366&z=12"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2552/e7bc356b4a014e42915b6050b8249028_s=350x_.JPG 350w, https://cdn.swbpg.com/t/2552/e7bc356b4a014e42915b6050b8249028_s=660x_.JPG 660w, https://cdn.swbpg.com/t/2552/e7bc356b4a014e42915b6050b8249028_s=860x_.JPG 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image style={{"maxWidth":180}} src={"https://cdn.swbpg.com/o/2552/5eb30947880648008bed59b2dce900f6.JPG"} svg={false} href={"https://www.mistopisy.cz/pruvodce/obec/7596/bulhary/vylety-turistika/"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={""}>
              </Image>

              <Text className="text-box" style={{"maxWidth":360}} content={"&nbsp;<br>Křižují se zde cyklostezky, a to ze směru Mikulov – Lednice, odbočka Valtice, Pavlov, Velké Bílovice, Zaječí, <span style=\"color: rgb(126, 211, 33);\">napojení na rakouské cyklostezky</span>.\n<br><br>Kdo by chtěl cestovat autobusem, je v dosahu 50 m od APARTMÁNŮ <span style=\"color: rgb(126, 211, 33);\">autobusová zastávka \"U Sadu\"</span>. <br><br>Také je zde prodejna potravin, různé restaurace, mnoho vinných sklípků a pošta.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--30 pt--30" style={{"marginTop":0,"marginBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"kontakt"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":1099}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--center" style={{"maxWidth":""}} content={"<span style=\"color: rgb(126, 211, 33);\">Aparmány U Sadu</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--18 w--500 text-box--invert ls--001" style={{"maxWidth":305,"paddingTop":0,"paddingBottom":3.84375}} content={"Ida Rusková<br>Bulhary 12\n<br>&nbsp;69201 Bulhary\n\n<br>IČ: 45628572&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":351}}>
              
              <Subtitle className="subtitle-box fs--20 subtitle-box--invert" style={{"maxWidth":200}} content={"APARTMÁN JIŽ OD 1400 Kč&nbsp;"}>
              </Subtitle>

              <Button className="btn-box btn-box--style3 fs--14 w--900 mb--0 mt--06" style={{"maxWidth":319,"marginTop":null,"marginBottom":null}} href={"#formular"} content={"REZERVACE&nbsp;&nbsp;"}>
              </Button>

              <Button className="btn-box btn-box--style3 fs--14" style={{"maxWidth":403}} href={"https://www.e-chalupy.cz/jizni_morava/ubytovani-bulhary-apartmany-u-sadu-7219.php"} content={"OBSAZENOST"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1" style={{"maxWidth":375,"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box title-box--style1 title-box--center ff--1 fs--18 w--900 title-box--invert ls--12 mb--06 mt--0" style={{"maxWidth":325,"marginTop":null,"paddingTop":0,"marginBottom":null,"paddingBottom":0}} content={"<span style=\"font-weight: normal;\">ida.ruskova@gmail.com<br>+420&nbsp;607 277 977</span><br>"}>
              </Title>

              <Image className="--center mt--0" style={{"maxWidth":31,"marginTop":null}} src={"https://cdn.swbpg.com/t/2552/a6025b588a2049999e48366024a90f47_s=350x_.png"} svg={false} href={"https://www.facebook.com/ApartmanyUSaduBulhary/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/2552/a6025b588a2049999e48366024a90f47_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="contact--l6" name={"stz0dt8t4oo"} layout={"l5"}>
        </Column>


        <VarColumn className="var-column mt--0 pb--0 pt--0" style={{"marginTop":null,"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(250,250,250,1)"}} name={"49ro0ciy5dq"} layout={"l2"}>
          
          <Clearfix >
          </Clearfix>

        </VarColumn>


        <Column className="mt--0 pb--0 pt--06" style={{"marginTop":null,"paddingTop":null,"paddingBottom":null}} name={"formular"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"Kontaktujte nás"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":600}} content={"Máte dotaz? Potřebujete poradit nebo jen nám chcete něco sdělit? Napište nám pomocí kontaktního formuláře - my se Vám zpětně ozveme."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Form style={{"paddingTop":0,"paddingBottom":0}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Jméno a příjmení"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Termín od","type":"text","required":false,"placeholder":"Poptávaný termín"},{"name":"Typ ubytování","type":"textarea","required":true,"placeholder":"Vyberte typ ubytování"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l3"}>
              </Form>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"unolsfo00rr"}>
        </Fullmap>


        <Fullmap className="--style2" name={"mapa"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"12"} place={"48.83601N, 16.73931E"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--40 pt--40" style={{"backgroundColor":"var(--color-main-bg-2)"}} name={"bb2bu4t468p"}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--right">
              
              <Title className="title-box title-box--center fs--24 title-box--invert" content={"Apartmány U Sadu"}>
              </Title>

              <Text className="text-box text-box--center fs--16 text-box--invert mt--10" style={{"maxWidth":400}} content={"Dovolená, kterou si zamilujete."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box fs--16 subtitle-box--invert" content={"Telefonní kontakt:"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"+420&nbsp;607 277 977"}>
              </Text>

              <Subtitle className="subtitle-box fs--16 subtitle-box--invert" content={"E-mail:"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"ida.ruskova@gmail.com"}>
              </Text>

              <Subtitle className="subtitle-box fs--16 subtitle-box--invert" content={"Adresa:"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"Bulhary 12, 692 01 Bulhary"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}